
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home',

  mounted: function () {
    // eslint-disable-next-line no-unused-expressions
    this.$store.state.perfectScrollbar?.update()
  }
})
