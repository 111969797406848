
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NotFound',

  data: function () {
    return {
      // time til redirect
      ttr: 5,

      timeoutIds: [] as number[]
    }
  },

  mounted: function () {
    // eslint-disable-next-line no-unused-expressions
    this.$store.state.perfectScrollbar?.update()

    for (let i = this.ttr; i > 0; i--) {
      this.timeoutIds.push(setTimeout(() => {
        this.ttr--
        if (this.ttr === 0) {
          this.$router.push('/')
        }
      }, i * 1000))
    }
  },

  unmounted: function () {
    this.timeoutIds.forEach((id) => { clearTimeout(id) })
  }
})
