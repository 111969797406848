import PerfectScrollbar from 'perfect-scrollbar'
import { createStore } from 'vuex'

export default createStore({
  state: {
    perfectScrollbar: null as PerfectScrollbar | null
  },
  mutations: {
    setPerfectScrollbar (state, ps : PerfectScrollbar) {
      state.perfectScrollbar = ps
    }
  },
  actions: {
  },
  modules: {
  }
})
