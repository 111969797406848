
import { defineComponent } from 'vue'

import axios from 'axios'

interface RawFormData {
  [key: string]: string | File | null,

  'form-name': string,
  name: string,
  email: string,
  message: string,
  file: File | null
}

// TODO: add direct contact info... ask patrick about this

export default defineComponent({
  name: 'Contact',
  data: function () {
    return {
      name: '',
      email: '',
      emailError: '',
      message: '',
      messageError: '',
      fileData: null as File | null,
      fileError: '',

      wasValidated: false,
      contactSubmitted: false,

      postSubmitTitle: 'Thank you! I will be in touch with you soon.',
      postSubmitSubtitle: 'If you do not receive a reply within a few days, please check your email\'s spam folder.',

      POST_SUBMIT_ERROR_TITLE: 'Something appears to have gone wrong...',
      POST_SUBMIT_ERROR_SUBTITLE: 'Please verify your internet connection and try again.'
    }
  },

  mounted: function () {
    // eslint-disable-next-line no-unused-expressions
    this.$store.state.perfectScrollbar?.update()
  },

  methods: {
    handleSubmit () {
      if (this.validate()) {
        const data = {
          'form-name': 'contact-form',
          name: this.name,
          email: this.email,
          message: this.message,
          file: null as File | null
        }
        if (this.fileData) data.file = this.fileData
        const formData = this.encode(data)
        // for (const key of formData.keys()) {
        //   console.log(key)
        // }
        // for (const value of formData.values()) {
        //   console.log(value)
        // }

        axios.post('/', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }

        // on success
        }).then(() => {
          // console.log('Form successfully submitted')
          this.contactSubmitted = true

        // on error
        }).catch((err) => {
          console.error(err)

          this.postSubmitTitle = this.POST_SUBMIT_ERROR_TITLE
          this.postSubmitSubtitle = this.POST_SUBMIT_ERROR_SUBTITLE
          this.contactSubmitted = true
        }).finally(() => {
          // makes for a smoother transition between states
          setTimeout(() => {
            const appContainer = document.querySelector('#appContainer')
            if (appContainer) {
              appContainer.scrollTop = 0
            }
          }, 500)

          setTimeout(() => {
            // eslint-disable-next-line no-unused-expressions
            this.$store.state.perfectScrollbar?.update()
          }, 750)
        })
      }
    },
    fileChange (event: Event) {
      const MAX_FILE_SIZE = 1000000

      const formInput = event.target as HTMLInputElement
      // console.log(formInput.files)

      if (formInput.files && formInput.files.length && formInput.files[0].size > MAX_FILE_SIZE) {
        this.fileError = 'file size exceeds 1MB, cannot upload'
        // console.log(formInput.files[0].size)
        this.fileData = null
      } else if (formInput.files && formInput.files.length) {
        this.fileError = ''
        this.fileData = formInput.files[0]
      } else {
        // console.log('empty filelist')
        this.fileError = ''
        this.fileData = null
      }
    },
    encode (data: RawFormData) {
      const formData = new FormData()
      for (const key of Object.keys(data)) {
        const value = data[key]
        if (value !== null) formData.append(key, value)
      }
      return formData
    },

    validate () {
      this.wasValidated = true
      this.updateValidate()

      if (!this.emailError && !this.messageError && !this.fileError) return true
    },
    updateValidate () {
      // only run function if submit button has been pressed (triggering validate)
      if (!this.wasValidated) return

      if (!this.email || !this.isValidEmail()) {
        this.emailError = 'A valid email address is required'
      } else this.emailError = ''
      if (!this.message) {
        this.messageError = 'A valid message is required'
      } else this.messageError = ''
    },
    isValidEmail () {
      const re = /.+@.+\..+/
      return re.test(this.email)
    }
  }
})
