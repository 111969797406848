
import { defineComponent } from 'vue'
import PerfectScrollbar from 'perfect-scrollbar'

export default defineComponent({
  name: 'App',

  mounted: function () {
    const ps = new PerfectScrollbar('#appContainer')
    this.$store.commit('setPerfectScrollbar', ps)
  }
})
